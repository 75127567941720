import React from "react";
import logo from "./logo.svg";
import "./App.css";

const SectionLineBreak = <hr style={{ width: "10%", margin: "5% auto" }}></hr>;

function App() {
  return (
    <div className="App">
      {/* <div className="heading">
        <h1>A MEMORIAL TRIBUTE TO RABBI LAMM, ZT”L</h1>
      </div>
      <div className="tributeContainer">
        <div>
          <p>Remarks by:</p>
          <p style={{ margin: "0 auto" }}>Family Members</p>
          <p style={{ margin: "0 auto" }}>Rabbi Dr. Ari Berman</p>
          <p style={{ margin: "0 auto" }}>Rabbi Dr. Yossi Levine</p>
          <p style={{ margin: "0 auto" }}>Rabbi Herschel Schachter</p>
          <p style={{ margin: "0 auto" }}>Dr. David Shatz</p>
        </div>
        <div style={{ fontSize: "1.2em" }}>
          <p>Tuesday, June 2, 2020</p>
          <p>12:30 PM EDT</p>
        </div>
        <div>
          <p style={{ fontWeight: 700, fontSize: "1.3em" }}>
            <a
              href="https://youtu.be/COKg8mwfNgY"
              rel="noopener noreferrer"
              target="_blank"
            >
              YOUTUBE LIVE STREAM LINK:
            </a>
          </p>
          <p>
            <a
              href="https://youtu.be/COKg8mwfNgY"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://youtu.be/COKg8mwfNgY
            </a>
          </p>
        </div>
      </div>

      <hr style={{ width: "10%", margin: "5% auto" }}></hr>
      <div className="shivaInfo">
        <h2>VIRTUAL SHIVA INFORMATION:</h2>
        <p>
          The individual aveilim (mourners) will sit shiva in their own homes
          and will join in a Zoom Virtual Shiva.
        </p>
        <h3 style={{ textDecorationLine: "underline" }}>
          The Visiting hours of the Virtual Shiva will be as follows:
        </h3>
        <h4 style={{ textDecorationLine: "underline" }}>
          Monday, June 1 through Friday, June 5
        </h4>
        <div style={{ width: "10%", margin: "5% auto" }}></div>
        <div>
          <p style={{ fontWeight: "700" }}>
            9:30 AM - 11:00 AM & 7:30 PM - 8:30 PM
          </p>
          <p>except Friday, which will conclude at 11:00 AM</p>
          <p>
            The family will be together on{" "}
            <a
              href="https://us02web.zoom.us/j/84675060038?pwd=MFp6MXVaeHFvUjQraDU1MGc4aEkyUT09"
              rel="noopener noreferrer"
              target="_blank"
            >
              {" "}
              Zoom{" "}
            </a>
          </p>
          <p style={{ width: "50%", margin: "auto" }}>
            <a
              href="https://us02web.zoom.us/j/84675060038?pwd=MFp6MXVaeHFvUjQraDU1MGc4aEkyUT09"
              rel="noopener noreferrer"
              target="_blank"
            >
              {" "}
              https://us02web.zoom.us/j/84675060038?pwd=MFp6MXVaeHFvUjQraDU1MGc4aEkyUT09{" "}
            </a>
          </p>
        </div>
        <hr style={{ width: "2%", margin: "3% auto" }}></hr>
        <div>
          <p style={{ fontWeight: "700" }}>
            1:30 PM - 4:00 PM & 6:00 PM - 7:00 PM
          </p>
          <h4>Zoom conversations with individual aveilim</h4>
          <p>
            (There will be no 1:30 PM session on Tuesday, due to the tribute to
            Dr. Lamm)
          </p>
        </div>
      </div>
      <div style={{ margin: "5% auto" }}></div>
      <div className="individualShiva">
        <div>
          <p>
            <span style={{ fontWeight: 700 }}>CHAYE: </span>
          </p>
          <p style={{ margin: "auto" }}>
            <a
              href="https://us02web.zoom.us/j/81377934488"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://us02web.zoom.us/j/81377934488
            </a>
          </p>
        </div>
        <hr style={{ width: "2%", margin: "3% auto" }}></hr>
        <div>
          <p>
            <span style={{ fontWeight: 700 }}>JOSHUA: </span>
          </p>
          <p style={{ margin: "auto" }}>
            <a
              href="https://us02web.zoom.us/j/89221486686?pwd=QkN1cG1wN1c5bnduRzIybkxDOCsvZz09"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://us02web.zoom.us/j/89221486686?pwd=QkN1cG1wN1c5bnduRzIybkxDOCsvZz09
            </a>
          </p>
        </div>
        <hr style={{ width: "2%", margin: "3% auto" }}></hr>
        <div>
          <p>
            <span style={{ fontWeight: 700 }}>SHALOM: </span>
          </p>
          <p style={{ margin: "auto" }}>
            <a
              href="https://us02web.zoom.us/j/83843250145?pwd=TWJWYTcrZGJLcDFCZzQ0cWp6SHJmZz09"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://us02web.zoom.us/j/83843250145?pwd=TWJWYTcrZGJLcDFCZzQ0cWp6SHJmZz09
            </a>
          </p>
        </div>
      </div>
      <hr style={{ width: "10%", margin: "5% auto" }}></hr>
      <div className="shivaContact">
        <h3>Contact Information</h3>
        <p>Chaye Lamm Warburg: potschaye@gmail.com - (201) 406-1993</p>
        <p>Joshua Lamm: lammmd@gmail.com - (516) 313-1851</p>
        <p>Shalom Lamm: slamm@ambitfamily.com - (917) 282-7375</p>
        <p>Mark Dratch: msdratch@gmail.com - (203) 858-9691</p>
      </div>
      <div style={{ width: "10%", margin: "5% auto" }}></div>
      <div className="nonShivaInfo">
        <h3>For non-Shiva visit information, contact:</h3>
        <p>Tina Lamm Lamm: (516) 551-0628</p>
        <p>Rivkie Lamm: (516) 313-6734</p>
        <p>Ahuva Warburg Halpern: (201) 220-5849</p>
      </div>*/}
      <div className="memoryEmail">
        <p>Please share your memories and photos with us at:</p>
        <p>
          <a
            href="mailto:RabbiNormanLammMemories@gmail.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            RabbiNormanLammMemories@gmail.com
          </a>
        </p>
      </div>
    </div>
  );
}

export default App;
